import React from 'react';
import Layout from '../../staticcomponents/layout';
import './style-guide.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';


export default function StyleGuide(){
    return (
        <>
        <Layout>
            <Container maxWidth="xl">
            <Grid container xl={12}>
            <div className='style-guide-cls '>
                <h1></h1>
            <p>H1 Tag Natompro medium</p>
            <h1 className='natom-medium'>Our latest Mobility </h1>
            <div>
                <p>CTA Natompro medium</p>
            <a href='' className='btn natom-medium'>our differentiators</a>
            </div>
           
            <div>
            <p>CTA Natompro regular</p>
            <a href='' className='btn natom-regular'>our differentiators</a>
            </div>

            
            <p>P Tag Natompro regular</p>
            <p className='natom-regular'>Our latest Mobility Supply Chain Guide looks at the specific supply chain challenges within the wider industry, what Cartus is doing to meet these challenges, and key recommendations to help organizations. Our latest Mobility Supply Chain Guide looks at the specific supply chain challenges within the wider industry, what Cartus is doing to meet these challenges, and key recommendations to help organizations. Our latest Mobility Supply Chain Guide looks at the specific supply chain challenges within the wider industry, what Cartus is doing to meet these challenges, and key recommendations to help organizations.</p>


            
            </div>
            </Grid>
            </Container>


        </Layout>
        </>
    )
}